import { Link } from "gatsby";
import React from "react";

const Logo = ({ navOpen }) => {
  return (
    <div className="logo-container">
      <Link to="/" className="text-logo">
        Kauneuden ilo
      </Link>
    </div>
  );
};

export default Logo;
