import { Link, useStaticQuery } from "gatsby";
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Header = ({ children, hero = true, theme, heroSmall }) => {
  const data = useStaticQuery(graphql`
    {
      contentfulHero {
        heroImage {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
            resizingBehavior: FILL
            layout: FULL_WIDTH
            width: 1200
            cropFocus: CENTER
          )
          title
        }
        heroText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  const image = getImage(data.contentfulHero.heroImage);
  const heroText = data.contentfulHero.heroText.childMarkdownRemark?.html;

  return (
    <header className={`page-header ${theme}`}>
      {children}
      {hero ? (
        <div
          className={`hero-container ${heroSmall && "small"} ${
            heroText && "frosted"
          }`}
        >
          <GatsbyImage
            image={image}
            alt={data.contentfulHero.heroImage.title}
          />
          {heroText && (
            <div className="hero-text">
              <span
                dangerouslySetInnerHTML={{
                  __html: heroText,
                }}
              ></span>
              <Link to="/ajanvaraus" className="button--outline light">
                Ajanvaraus
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="nav-spacer"></div>
      )}
    </header>
  );
};

export default Header;
