import React from "react";
import Socials from "./Socials";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-container">
        <div className="col">
          <h3>Yhteystiedot</h3>
          <ul>
            <li>Voionmaankatu 8, 40100 JYVÄSKYLÄ</li>
            <li>
              <a href="tel:+358 40 846 4160">040 846 4160</a>
            </li>
            <li>
              <a href="mailto:info@kauneudenilo.fi">info@kauneudenilo.fi</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Aukioloajat</h3>
          <ul>
            <li>Hoitola avoinna ma-la sopimuksen mukaan</li>
          </ul>
        </div>
        <div className="col">
          <Socials />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
