import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

const Seo = ({ title, description }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          defaultTitle
          siteUrl
          image
          defaultDescription
          titleTemplate
        }
      }
    }
  `);

  const { defaultTitle, siteUrl, image, defaultDescription, titleTemplate } =
    site.siteMetadata;
  const createdSeo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
  };
  return (
    <Helmet
      title={createdSeo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: "fi" }}
    >
      <meta name="description" content={createdSeo.description} />
      <meta name="image" content={createdSeo.image} />
      {createdSeo.url && <meta property="og:url" content={createdSeo.url} />}
      {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}
      {createdSeo.title && (
        <meta property="og:title" content={createdSeo.title} />
      )}
      {createdSeo.description && (
        <meta property="og:description" content={createdSeo.description} />
      )}
      {createdSeo.image && (
        <meta property="og:image" content={createdSeo.image} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {createdSeo.title && (
        <meta name="twitter:title" content={createdSeo.title} />
      )}
      {createdSeo.description && (
        <meta name="twitter:description" content={createdSeo.description} />
      )}
      {createdSeo.image && (
        <meta name="twitter:image" content={createdSeo.image} />
      )}
    </Helmet>
  );
};

export default Seo;
