import React, { useEffect, useState } from "react";
import "../sass/index.scss";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";

const Layout = ({
  children,
  heroImage,
  hero,
  heroSmall = false,
  theme = "light",
}) => {
  const [navigationBackground, setNavigationBackground] = useState(false);
  const handleScroll = () => {
    let windowScroll = window.scrollY;

    const heroContainer = document.querySelector(".hero-container");
    const navigation = document.querySelector(".navigation");

    if (navigation) {
      windowScroll >= heroContainer?.clientHeight - navigation?.clientHeight
        ? setNavigationBackground(true)
        : setNavigationBackground(false);
    }
    if (!heroContainer) {
      windowScroll >= 10
        ? setNavigationBackground(true)
        : setNavigationBackground(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  return (
    <React.Fragment>
      <main className="page">
        <Header
          heroImage={heroImage}
          hero={hero}
          theme={theme}
          heroSmall={heroSmall}
        >
          <Navigation navigationBackground={navigationBackground} />
        </Header>
        <div className="page-container">{children}</div>
        <Footer />
      </main>
    </React.Fragment>
  );
};
export default Layout;
